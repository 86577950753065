import React from "react";

const TextMarquee = () => {
  const scrollSpeed = 2; 
  return (
    <>
    {/* <div className="marquee">
      <marquee
        className="m_style text1"
        behavior="scroll"
        direction="left"
        scrollamount={scrollSpeed}
      >
       MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap
      </marquee>
      <marquee
        className="m_style text2"
        behavior="scroll"
        direction="right"
        scrollamount={scrollSpeed}
      >
       MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap
      </marquee>
      <marquee
        className="m_style text3"
        behavior="scroll"
        direction="left"
        scrollamount={scrollSpeed}
      >
       MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap MOMO V2 Swap
      </marquee>
    </div> */}
    </>

  );
};

export default TextMarquee;
