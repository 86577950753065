import React, { useState } from 'react';
import styled from 'styled-components';

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const SwitchContainer = styled.label`
  display: inline-block;
  position: relative;
  width: 60px;
  height: 34px;
`;

interface SliderProps {
  isOn: boolean;
}

const Slider = styled.span<SliderProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => (props.isOn ? '#2196F3' : '#ccc')};
  transition: .4s;
  border-radius: 34px;

  &::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
    transform: ${props => (props.isOn ? 'translateX(26px)' : 'translateX(0)')};
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const ToggleSwitch: React.FC<SwitchProps> = ({ checked, onChange }) => {
  const [isOn, setIsOn] = useState(checked);

  const handleChange = () => {
    setIsOn(!isOn);
    onChange(!isOn);
  };

  return (
    <SwitchContainer>
      <HiddenCheckbox checked={isOn} onChange={handleChange} />
      <Slider isOn={isOn} />
    </SwitchContainer>
  );
};

export default ToggleSwitch;
