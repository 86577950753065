import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineSwap, AiOutlineDashboard } from "react-icons/ai";
import { MdOutlineHowToVote } from "react-icons/md";
import { RiStockLine } from "react-icons/ri";
import { RiNftFill } from "react-icons/ri";
import { CiMenuBurger } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";

const NavMenu = () => {
  const isPathActive = (path: any) => {
    const currentUrl = window.location.href;
    return currentUrl.includes(path) && currentUrl.includes("#");
  };
  return (
    <div className="my_custom_mobile_menu">
      {/* <div className="mobile_nav_menu_icon">
        <CiMenuBurger />
      </div>
      <div className="my_custom_mobile_menu_content">
        <div className="mcs_items">
          <Link to="/swap" className={isPathActive("/swap") ? "active" : ""}>
            <span>
              <AiOutlineSwap />
            </span>
            Swap
          </Link>
          <Link to="/pools" className={isPathActive("/pools") ? "active" : ""}>
            <span>
              <AiOutlineDashboard />
            </span>
            Pools
          </Link>
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <RiStockLine />
            </span>
            Staking
          </a>
          <a href="/#/nfts" rel="noopener noreferrer">
            <span>
              <RiNftFill />
            </span>
            NFT
          </a>

          <a href="/#/vote" rel="noopener noreferrer">
            <span>
              <MdOutlineHowToVote />
            </span>
            Vote
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default NavMenu;
