import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineSwap,AiOutlineDashboard } from "react-icons/ai";
import { MdOutlineHowToVote } from "react-icons/md";
import { RiStockLine } from "react-icons/ri";
import { GiFarmer } from "react-icons/gi";
import { RiNftFill } from "react-icons/ri";

const CustomSidebar = () => {
  const isPathActive = (path) => {
    const currentUrl = window.location.href;
    return currentUrl.includes(path) && currentUrl.includes("#");
  };
  return (
    <>
    {/* <div className="my_custom_sidebar">
      <div className="mcs_items">
        <Link to="/swap" className={isPathActive("/swap") ? "active" : ""}>
          <span>
            <AiOutlineSwap />
          </span>
          Swap
        </Link>
        <Link to="/pools" className={isPathActive("/pools") ? "active" : ""}>
          <span>
            <AiOutlineDashboard />
          </span>
          Pools
        </Link>
        <a
          href="https://app.momo-erc.com/v2/staking.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            <RiStockLine />
          </span>
          Staking
        </a>
        <a
          href="/#/nfts"
          rel="noopener noreferrer"
        >
          <span>
            <RiNftFill/>
          </span>
          NFT
        </a>
        
        <a
          href="/#/vote"
          rel="noopener noreferrer"
        >
          <span>
            <MdOutlineHowToVote />
          </span>
          Vote
        </a>
      </div>
    </div> */}
    </>

  );
};

export default CustomSidebar;
