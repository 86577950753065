import React from "react";

const CryptoLogoMarquee = () => {
  const scrollSpeed = 2;
  return (
    <>
      {/* <div className="crypto_marquee">
        <marquee behavior="scroll" direction="left" scrollamount={scrollSpeed}>
          <div className="d-flex" style={{ gap: "40px", display: "flex" }}>
            <div className="crypto_mr_item">
              <span>MOMO V2</span>
              <img style={{ borderRadius: "100%" }} src="/logo.png" alt="" />
            </div>
            <div className="crypto_mr_item">
              <span>USDT</span>
              <img
                src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
                alt=""
              />
            </div>

            <div className="crypto_mr_item">
              <span>BNB</span>
              <img
                style={{ borderRadius: "100%" }}
                src="https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png"
                alt=""
              />
            </div>
            <div className="crypto_mr_item">
              <span>RACA Token</span>
              <img
                style={{ borderRadius: "100%" }}
                src="https://s2.coinmarketcap.com/static/img/coins/64x64/11346.png"
                alt=""
              />
            </div>
          </div>
        </marquee>
      </div> */}
    </>
  );
};

export default CryptoLogoMarquee;
